import * as React from 'react';
import { FunctionComponent } from 'react';
import './Toggle.scss';

interface Props {
    label: string; 
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const SingleToggle: FunctionComponent<Props> = (props: Props) => {


    return (
        <label className="toggle">
            <span>{props.label}</span>
            <input type="checkbox" checked={props.checked} onChange={(e) => props.onChange(e.currentTarget.checked)} />
            <span className="checkmark"></span>          
        </label>
    );
}

export default SingleToggle;
