import * as React from 'react';
import { FunctionComponent } from 'react';
import './Spinner.scss';

interface SpinnerProps {
    visible: boolean;
}

const Spinner: FunctionComponent<SpinnerProps> = (props: SpinnerProps) => {
    var classNames = "loader";
    if (props.visible) {
        classNames += " show"
    }

    return (
        <div className={classNames}>
            <div className="spinner"></div>
        </div>
    );
}

export default Spinner;
