import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { FunctionComponent } from 'react';
import { IConfiguration } from '../model/Configuration';
import { Settings } from '../Settings';
import { DataContext } from '../contexts/DataContext';
import './ShareDialog.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ReactComponent as Bracket } from '../assets/Button-icon.svg';
import emailLogo from '../assets/email.png';
import whatsappLogo from '../assets/whatsapp.png';
import { useState } from 'react';
import Popup from './Popup';
import { saveAs, } from 'file-saver';

interface Props {
    visible: boolean;
    onClose: () => void;
}

const GenerateUrl = (configuration: IConfiguration, region: number) => {    
    let productsStrings: string[] = [];

    for (let index = 0; index < configuration.products.length; index++) {
        const element = configuration.products[index];
        productsStrings.push(`${element.layer}.${element.product}.${element.color}`);
    }
    
    return `${Settings.server}/api/configurator/pdf?houseid=${configuration.houseId}&landscapeid=${configuration.landscapeId}&products=${productsStrings.join('-')}&regionId=${region}`;
}

const ShareDialog: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('share');
    const { currentConfiguration } = useContext(ConfiguratorContext);
    const { data } = useContext(DataContext);
    const [ copied, setCopied ] = useState(false);
   
    const Close = function () {
        props.onClose();
    }

    const onCopied = () => {
        setCopied(true);
        window.setTimeout(() => {
            setCopied(false);
        }, 5000);
    }

    const download = () => {
        var pdfurl = GenerateUrl(currentConfiguration, data.region.id);
        saveAs(pdfurl, "PREFA-Konfigurator.pdf");
    }

    const openLinkInParent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        
        if (window.parent) {
            window.parent.postMessage({
                'type': 'link',
                'target': 'blank',
                'url': e.currentTarget.href
            }, "*");
            e.preventDefault();
        }
    }

    let url = window.location.href;

    try{
        const params = new URLSearchParams(window.location.search);
        const rootUrl = params.get("sharelink");

        if(rootUrl){
            const uri = new URL(rootUrl);
            uri.hash = window.location.hash;
            url = uri.href;
        }
    }catch{}


    var messageTitle = t('title', {ns: 'meta'});
    var messageBody = t('emailbody').replace('{link}', url);

    return (
        <>
            <Popup visible={props.visible} onClose={Close} maxWidth={550}>
                <div className="sharedialog">
                    <div>
                        <h2>{t("header")}</h2>
                        <p>
                            <a href={`mailto:?subject=${encodeURIComponent(messageTitle)}&body=${encodeURIComponent(messageBody)}`} target='_blank'>
                                <img src={emailLogo} alt="email" />
                                E-Mail
                            </a>
                            <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(messageBody)}`} onClick={(e) => openLinkInParent(e)} target='_blank' rel='noopener noreferrer'>
                                <img src={whatsappLogo} alt="whatsapp" />
                                Whatsapp
                            </a>
                        </p>
                        <p className="url">
                            <input type="text" readOnly value={url} onClick={(e) => e.currentTarget.select()}/>
                            <CopyToClipboard text={url} onCopy={onCopied}>
                                <button className="button large white border">{t('copy')}</button>
                            </CopyToClipboard>
                        </p>

                    </div>
                    <div>
                        <button onClick={() => download()} className="button large white border edge"><span><Bracket />{t('download')}</span></button>
                    </div>
                </div>
            </Popup>
            {copied && <div className="infomessage">
                {t('copied')}
            </div>}
        </>
    );
}

export default ShareDialog;
