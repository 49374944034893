import React, { createContext, useState, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ICustomizerData } from "../model/CustomizerData";
import { Settings } from "../Settings";

export interface DataContextState {
    loading: boolean;
    data: ICustomizerData;
};

const contextDefaultValues: DataContextState = {
    loading: false,
    data: {
        cacheKey: '1',
        region: {
            id: 0,
            identifier: 'de',
            locale: 'de',
            defaultConfiguration: []
        },
        regions: [],
        houses: [],
        landscapes: [],
        productGroups: [],
        products: [],
    },
};

export const DataContext = createContext<DataContextState>(
    contextDefaultValues
);

export const DataProvider: FC<{children: React.ReactNode}> = ({ children }) => {
    const [loading, setLoading] = useState(contextDefaultValues.loading);
    const [data, setData] = useState(contextDefaultValues.data);
    const {i18n} = useTranslation();

    useEffect(() => {
        setLoading(true);

        var region = '';
        var pathArray = window.location.pathname.split('/');
        if(pathArray.length > 1){
            region = pathArray[1];
        }

        fetch(`${Settings.server}/api/configurator?region=${region}`)
            .then(res => res.json())
            .then(res => {
                setData(res)
                i18n.changeLanguage(res.region.locale)
                    .then(() => setLoading(false));
            })
            .catch(err => console.log(err));
    }, [i18n]);

    useEffect(() => {
        document.title = i18n.t('title', {ns: 'meta'});
    }, [i18n.language])

    return (
        <DataContext.Provider
            value={{
                loading,
                data
            }}
        >
            {children}
        </DataContext.Provider>
    );
};