import React, { createContext, useState, FC, useEffect } from "react";
import { HotspotType, ICustomizerData, ILayer, IProduct } from "../model/CustomizerData";
import { IConfiguration } from "../model/Configuration";

export type ConfiguratorCategory = HotspotType | "None";

export interface ConfiguratorContextState {
    currentConfiguration: IConfiguration;
    isNight: boolean;
    selectedCategory: ConfiguratorCategory;
    setSelectedCategory: (category: ConfiguratorCategory) => void;
    setIsNight: (isNight:boolean) => void;
    changeHouse: (houseId:number) => void;
    changeLandscape: (landscapeId:number) => void;
    changeProducts: (layerIds: number[], productId: number, colorId: number) => void;
    selectedProductGroup: number | undefined;
    setSelectedProductGroup: (group: number | undefined) => void;
    selectedProduct: number | undefined;
    setSelectedProduct: (product: number | undefined) => void;
    selectedLayers: ILayer[];
    setSelectedLayers: (layers: ILayer[]) => void;
 };

const contextDefaultValues: ConfiguratorContextState = {
    currentConfiguration: {
        houseId: 0,
        landscapeId: 0,
        products: []
    },
    isNight: false,
    selectedCategory: "None",
    setSelectedCategory: () => {},
    setIsNight: () => {},
    changeHouse: () => {},
    changeLandscape: () => {},
    changeProducts: () => {},
    selectedProductGroup: undefined,
    setSelectedProductGroup: () => {},
    selectedProduct: undefined,
    setSelectedProduct: () => {},
    selectedLayers: [], 
    setSelectedLayers: () => {},
};

export const ConfiguratorContext = createContext<ConfiguratorContextState>(
    contextDefaultValues
);

export const ConfiguratorProvider: FC<{ data: ICustomizerData, children: React.ReactNode }> = ({ children, data }) => {
    let [currentConfiguration, setCurrentConfiguration] = useState(contextDefaultValues.currentConfiguration);
    const [selectedCategory, setSelectedCategory] = useState<ConfiguratorCategory>("None");
    const [isNight, setIsNight] = useState(false);
    const [selectedProductGroup, setSelectedProductGroup] = useState<number | undefined>(undefined);
    const [selectedProduct, setSelectedProduct] = useState<number | undefined>(undefined);
    const [selectedLayers, setSelectedLayers] = useState<ILayer[]>([]);

    useEffect(() => {
        if(data.houses.length > 0){
            const params = new URLSearchParams(window.location.hash.replace('#', ''));
            let houseId = params.get("house") || "";
            let landscapeId = params.get("landscape") || "";
            let productsString = params.get("products");
            let startingProducts: {layer:number, product:number, color:number}[] = [];

            var house = data.houses.find(e => e.isDefault);
            if(houseId){
                house = data.houses.find(e => e.id === parseInt(houseId));
            }
            if(house === undefined){
                house = data.houses[0];
            }
            
            var landscape = data.landscapes.find(e => e.isDefault);
            if(landscapeId){
                landscape = data.landscapes.find(e => e.id === parseInt(landscapeId));
            }
            if(landscape === undefined){
                landscape = data.landscapes[0];
            }

            var config: IConfiguration = {
                houseId: house.id,
                landscapeId: landscape.id,
                products: [],
            };

            if(productsString){
                startingProducts = productsString.split("-").map(e => {
                    const values = e.split(".");
                    return {layer: parseInt(values[0]), product: parseInt(values[1]), color: parseInt(values[2]) || 0}
                });
            }

            var defaultProducts:{product:IProduct, color:number}[] = [];

            for (let index = 0; index < data.region.defaultConfiguration.length; index++) {
                const color = data.region.defaultConfiguration[index].value;
                const productId = data.region.defaultConfiguration[index].key;

                const product = data.products.find(e => e.id == productId && e.houses.includes(house!.id));

                if(product != undefined){
                    defaultProducts.push({product: product, color: color});
                }
            }

            for (let index = 0; index < house.layers.length; index++) {
                const layer = house.layers[index];
                const defaultProduct = defaultProducts.find(e => e.product.type == layer.type);

                if(defaultProduct != undefined){
                    defaultProducts = defaultProducts.filter(e => e != defaultProduct);
                }

                let startingProduct = startingProducts.find(e => e.layer === layer.id);
                
                if(startingProduct){
                    const product = data.products.find(e => e.id === (startingProduct === undefined ? 0 : startingProduct.product));
                    const color = startingProduct.color;

                    if(product != undefined){
                        config.products.push({layer: layer.id, product: product.id, color: color});
                    }
                }else if(defaultProduct){
                    if((layer.type != "SnowStopper" && layer.type != "SolarPanel") || !productsString){
                        config.products.push({layer: layer.id, product: defaultProduct.product.id, color: defaultProduct.color});
                    }
                }else if((layer.type != "SnowStopper" && layer.type != "SolarPanel")){
                    const product = data.products.find(e => e.type === layer.type);
                    let color = 0;

                    if(product != undefined){
                        if(product.colors.length > 0){
                            color = product.colors[0].id;
                        }

                        config.products.push({layer: layer.id, product: product.id, color: color});
                    }
                }
            }

            if(houseId){
                config.houseId = parseInt(houseId);
            }

            setCurrentConfiguration(config);

        }
    }, [data]);


    useEffect(() => {
        if(currentConfiguration.products.length === 0){
            return;
        }
    
        let productsStrings: string[] = [];
    
        for (let index = 0; index < currentConfiguration.products.length; index++) {
            const element = currentConfiguration.products[index];
            productsStrings.push(`${element.layer}.${element.product}.${element.color}`);
        }
    
        var hash = `house=${currentConfiguration.houseId}&landscape=${currentConfiguration.landscapeId}&products=${productsStrings.join('-')}`;
        window.location.hash = hash;

        if (window.parent) {
            window.parent.postMessage({
                'hash': hash
            }, "*");
        }
    }, [currentConfiguration]);




    const changeHouse = (houseId:number) => {
        const oldOptions: {[key:string]: number[][]} = {};
        const oldHouse = data.houses.find(e => e.id === currentConfiguration.houseId);
        if(oldHouse !== undefined){
            for (let i = 0; i < oldHouse.layers.length; i++) {
                const layer = oldHouse.layers[i];
                const option = currentConfiguration.products.find(e => e.layer == layer.id);

                if(option){
                    if(oldOptions[layer.type] === undefined){
                        oldOptions[layer.type] = [];
                    }
                    oldOptions[layer.type].push([option.product, option.color]);
                }
            }
        }



        const house = data.houses.find(e => e.id === houseId);
        if(house === undefined){
            return;
        }

        var config: IConfiguration = {
            houseId: house.id,
            landscapeId: currentConfiguration.landscapeId,
            products: [],
        };

        var defaultProducts:{product:IProduct, color:number}[] = [];

        for (let index = 0; index < data.region.defaultConfiguration.length; index++) {
            const color = data.region.defaultConfiguration[index].value;
            const productId = data.region.defaultConfiguration[index].key;

            const product = data.products.find(e => e.id == productId && e.houses.includes(house!.id));

            if(product != undefined && (product.type != "SnowStopper" && product.type != "SolarPanel")){
                defaultProducts.push({product: product, color: color});
            }
        }

        for (let index = 0; index < house.layers.length; index++) {
            const layer = house.layers[index];
            const defaultProduct = defaultProducts.find(e => e.product.type == layer.type);

            let productId = 0;
            let colorId = 0

            if(defaultProduct != undefined){
                defaultProducts = defaultProducts.filter(e => e != defaultProduct);

                productId = defaultProduct.product.id;
                colorId = defaultProduct.color;
            }

            if(oldOptions[layer.type] !== undefined && oldOptions[layer.type].length > 0){
                var option = oldOptions[layer.type].shift();

                if(option !== undefined){
                    let product = data.products.find(e => e.id === option![0] && e.houses.includes(houseId));

                    if(product === undefined){
                        if(option![0] == 51){
                            product = data.products.find(e => e.id === 12 && e.houses.includes(houseId));
                            option![0] = 12;
                        }else if(option![0] == 12){
                            product = data.products.find(e => e.id === 51 && e.houses.includes(houseId));
                            option![0] = 51;
                        }
                    }

                    if(product !== undefined){
                        productId = option[0];
                        colorId = option[1];
                    }else{
                        const product = data.products.find(e => e.id === productId);
                        if(product !== undefined && product.colors.some(e => e.id == option![1])){
                            colorId = option[1];
                        }
                    }
                }
            }
            
            if(productId === 0 && (layer.type != "SnowStopper" && layer.type != "SolarPanel")){
                const product = data.products.find(e => e.type === layer.type && e.houses.includes(houseId));
                if(product !== undefined){
                    productId = product.id;

                    if(product !== undefined){
                        if(product.colors.length > 0){
                            colorId = product.colors[0].id;
                        }
                    }
                }
            }

            if(productId !== 0){
                config.products.push({layer: layer.id, product: productId, color: colorId});
            }
        }

        setCurrentConfiguration(config);
    }

    const changeLandscape = (landscapeId:number) => {
        setCurrentConfiguration({...currentConfiguration, landscapeId: landscapeId});
    }

    const changeProducts = (layerIds: number[], productId: number, colorId: number) => {
        const products = currentConfiguration.products.filter(e => !layerIds.includes(e.layer));

        if(productId !== 0){
            for (let i = 0; i < layerIds.length; i++) {
                products.push({layer: layerIds[i], product: productId, color: colorId});
            }
        }
        
        setCurrentConfiguration({...currentConfiguration, products: products});
        currentConfiguration = {...currentConfiguration, products: products};
    }

    return (
        <ConfiguratorContext.Provider
            value={{
                currentConfiguration,
                isNight,
                selectedCategory,
                setSelectedCategory,
                setIsNight,
                changeHouse,
                changeLandscape,
                changeProducts,
                selectedProductGroup, 
                setSelectedProductGroup,
                selectedProduct,
                setSelectedProduct,
                selectedLayers, 
                setSelectedLayers,
            }}
        >
            {children}
        </ConfiguratorContext.Provider>
    );
};