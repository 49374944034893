import React, { useContext } from 'react';
import { ConfiguratorContext, ConfiguratorProvider } from './contexts/ConfiguratorContext';
import { DataContext } from './contexts/DataContext';
import './App.scss';
import Configurator from './components/Configurator';

function App() {
    const { data } = useContext(DataContext);
    
    return (
        <ConfiguratorProvider data={data}>
            <ConfiguratorContext.Consumer>
                {() =>
                    <div className="prefa-configurator">
                        <div className="prefa-container">
                            <Configurator />
                        </div>
                    </div>}
            </ConfiguratorContext.Consumer>
        </ConfiguratorProvider>
    );
}

export default App;
