import React, { FunctionComponent, useContext } from 'react';
import { ConfiguratorContext, ConfiguratorCategory } from '../contexts/ConfiguratorContext';
import HousePicker from './HousePicker';
import LandscapePicker from './LandscapePicker';
import ProductPicker from './ProductPicker';
import './ConfiguratorMenu.scss';
import { ReactComponent as HouseTypeIcon } from '../assets/Haustyp icon.svg';
import { ReactComponent as RoofIcon } from '../assets/Dach icon.svg';
import { ReactComponent as DrainageIcon } from '../assets/Dachentwasserung icon.svg';
import { ReactComponent as FacadeIcon } from '../assets/fassade icon.svg';
import { ReactComponent as WindowIcon } from '../assets/window icon.svg';
import { ReactComponent as LandscapeIcon } from '../assets/scenery icon.svg';
import { ReactComponent as CloseIcon } from '../assets/schliessen.svg';
import { useTranslation } from "react-i18next";
import { DataContext } from '../contexts/DataContext';

interface Props {

}

const ConfiguratorMenu: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('menu');
    const { currentConfiguration, selectedCategory, setSelectedCategory, setSelectedProductGroup, setSelectedProduct } = useContext(ConfiguratorContext);
    const { data } = useContext(DataContext);

    if (currentConfiguration === undefined) {
        return null;
    }

    const house = data.houses.find(e => e.id === currentConfiguration.houseId);

    if (house === undefined) {
        return null;
    }


    const onMenuClick = (category: ConfiguratorCategory) => {
        if(category !== "None"){
            setSelectedProductGroup(undefined);
            setSelectedProduct(undefined);
            setSelectedCategory(category);
        }
    }

    let content : JSX.Element | null = null;

    if(selectedCategory === "HouseType"){
        content = <HousePicker />;
    }else if(selectedCategory === "Landscape"){
        content = <LandscapePicker />;
    }else if(selectedCategory === "Roof"){
        content = <ProductPicker key="roof" productType="Roof" />;
    }else if(selectedCategory === "Facade"){
        content = <ProductPicker key="facade" productType="Facade" />;
    }else if(selectedCategory === "Drainage"){
        content = <ProductPicker key="drainage" productType="Drainage" />;
    }else if(selectedCategory === "Window"){
        content = <ProductPicker key="window" productType="Window" />;
    }

    const hasRoof = house.layers.some(e => e.type === "Roof");
    const hasDrainage = house.layers.some(e => e.type === "Drainage");
    const hasFacade = house.layers.some(e => e.type === "Facade");
    const hasWindow = house.layers.some(e => e.type === "Window");

    return (
        <div className={ "configuratorMenu" + (selectedCategory !== "None" ? " open" : "")}>
            <div className="icons">
                <button className={selectedCategory === "HouseType" ? "selected" : ""} onClick={() => onMenuClick("HouseType")}>
                    <HouseTypeIcon />
                    <p><span className="number">01</span> <span>{t('housetype')}</span></p>
                </button>

                {hasRoof && 
                <button className={selectedCategory === "Roof" ? "selected" : ""} onClick={() => onMenuClick("Roof")}>
                    <RoofIcon />
                    <p><span className="number">02</span> <span>{t('roof')}</span></p>
                </button>}

                {hasDrainage && 
                <button className={selectedCategory === "Drainage" ? "selected" : ""} onClick={() => onMenuClick("Drainage")}>
                    <DrainageIcon />
                    <p><span className="number">03</span> <span>{t('drainage')}</span></p>
                </button>}

                {hasFacade && 
                <button className={selectedCategory === "Facade" ? "selected" : ""} onClick={() => onMenuClick("Facade")}>
                    <FacadeIcon />
                    <p><span className="number">04</span> <span>{t('facade')}</span></p>
                </button>}

                {hasWindow && 
                <button className={selectedCategory === "Window" ? "selected" : ""} onClick={() => onMenuClick("Window")}>
                    <WindowIcon />
                    <p><span className="number">05</span> <span>{t('windows')}</span></p>
                </button>}

                <button className={selectedCategory === "Landscape" ? "selected" : ""} onClick={() => onMenuClick("Landscape")}>
                    <LandscapeIcon />
                    <p><span className="number">06</span> <span>{t('landscape')}</span></p>
                </button>
            </div>
            <div className="menuContainer" onClick={() => setSelectedCategory("None")}>
                <div className="menuContent" onClick={(e) => e.stopPropagation()}>
                    <button className="close" onClick={() => setSelectedCategory("None")}><CloseIcon /></button>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default ConfiguratorMenu;
