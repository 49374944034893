import React, { FunctionComponent, useContext } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { DataContext } from '../contexts/DataContext';
import './Hotspots.scss';
import { IHotspot } from '../model/CustomizerData';
import useWindowSize from '../hooks/useWindowSize';

interface Props {

}

const Hotspots: FunctionComponent<Props> = (props: Props) => {
    const { data } = useContext(DataContext);
    const { currentConfiguration, setSelectedCategory, selectedCategory } = useContext(ConfiguratorContext);
    const { width, height } = useWindowSize();
    const aspectRatio = 2500 / 1750;

    if (currentConfiguration === undefined) {
        return null;
    }

    const house = data.houses.find(e => e.id === currentConfiguration.houseId);

    if (house === undefined) {
        return null;
    }

    const getLabel = (hotspot:IHotspot) => {
        switch(hotspot.type){
            case "HouseType":
                return "01";
            case "Roof":
                return "02";
            case "Drainage":
                return "03";
            case "Facade":
                return "04";
            case "Window":
                return "05";
            case "Landscape":
                return "06";
            default:
                return hotspot.type
        }
    }

    let containerWidth = width;
    let containerHeight = width / aspectRatio;
    let top = 0;
    let left = 0;

    if(containerHeight > height){
        top = (containerHeight - height) / 2;
    }else{
        containerHeight = height;
        containerWidth = height * aspectRatio;

        left = (containerWidth - width) / 2;
    }

    return (
        <div className="hotspots" style={{width:containerWidth, height:containerHeight, left: -left, top: -top}} onClick={() => setSelectedCategory("None")}>
            {house.hotspots.map(hotspot => <div className={"hotspot" + (selectedCategory === hotspot.type ? " selected" : "")} style={{left:`${hotspot.x}%`, top:`${hotspot.y}%`}} onClick={(e) => {setSelectedCategory(hotspot.type); e.stopPropagation()}}>
                {getLabel(hotspot)}
            </div>)}
        </div>
    );
}

export default Hotspots;
