import * as React from 'react';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import SingleToggle from './SingleToggle';
import './Menu.scss';
import ShareDialog from './ShareDialog';
import { ReactComponent as Bracket } from '../assets/Button-icon.svg';
import ContactDialog from './ContactDialog';

interface Props {

}


const Menu: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('menu');
    const { isNight, setIsNight } = useContext(ConfiguratorContext);
    const [share, setShare] = useState(false);
    const [contact, setContact] = useState(false);


    return (
        <>
            <header>
                <div className="button large edge white">
                    <span>
                        <SingleToggle checked={isNight} onChange={(checked) => setIsNight(checked)} label={t('day')} />
                    </span>
                </div>
                <button className="button large edge red" onClick={() => setContact(true)}>
                    <span><Bracket />{t("contact")}</span>
                </button>
                <button className="button large edge grey" onClick={() => setShare(true)}>
                    <span><Bracket />{t("download pdf")}</span>
                </button>
            </header>

            <ShareDialog visible={share} onClose={() => setShare(false)} />

            <ContactDialog visible={contact} onClose={() => setContact(false)} />
        </>
    );
}

export default Menu;
