import React, { FunctionComponent } from 'react';
import './OptionList.scss';

export interface IOption {
    id: number,
    thumbnail: string;
    label: string;
    selected: boolean;
    disabled: boolean;
}

interface Props {
    options: IOption[];
    onOptionClicked: (option:IOption) => void;
}

const OptionList: FunctionComponent<Props> = (props: Props) => {

    return (
        <div className="optionlist">
            {props.options.map(e => <div key={e.id} className={"option" + (e.selected ? " selected" : "") + (e.disabled ? " disabled" : "")} onClick={() => (!e.disabled) && props.onOptionClicked(e)}>
                <img src={e.thumbnail} alt={e.label} />
                <span>{e.label}</span>
            </div>)}
        </div>
    );
}

export default OptionList;
