import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { DataContext, DataProvider } from './contexts/DataContext';
import Spinner from './components/Spinner';
import i18next, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import BackendAdapter from 'i18next-multiload-backend-adapter';
import objectFitImages from 'object-fit-images';

i18next
.use(BackendAdapter)
.use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    lng: "",
    fallbackLng: 'en',
    ns: ["menu", "houses", "landscapes", "roof", "drainage", "facade", "window", "color", "meta", "share", "infobox", "cookies", "contact", "footer"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backend: HttpApi,
      backendOption: {
        allowMultiLoading: true,
        loadPath: '/api/translate/?locales={{lng}}&ns={{ns}}',
      } 
    },
    //debug: process.env.NODE_ENV === "development",
  } as InitOptions);
  

objectFitImages();

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
 }
 window.addEventListener('resize', appHeight)
 appHeight()

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<Spinner visible={true} />}>
            <DataProvider>
                <DataContext.Consumer >
                    {(context) => context.loading ? <Spinner visible={true} /> : <App />}
                </DataContext.Consumer>
            </DataProvider>
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById('configurator')
);

