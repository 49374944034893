import React from 'react';
import { debounce } from 'ts-debounce';
import { IConfiguration } from '../model/Configuration';
import { Settings } from '../Settings';
import './Visualization.scss';
import Spinner from './Spinner';
import { ConfiguratorCategory } from '../contexts/ConfiguratorContext';
import { ILayer } from '../model/CustomizerData';

const GenerateUrl = (configuration: IConfiguration, isNight: boolean, cacheKey: string) => {
    if(configuration.products.length === 0){
        return "";
    }

    let productsStrings: string[] = [];

    for (let index = 0; index < configuration.products.length; index++) {
        const element = configuration.products[index];
        productsStrings.push(`${element.layer}.${element.product}.${element.color}`);
    }

    return `${Settings.server}/api/configurator/rendering?houseid=${configuration.houseId}&landscapeid=${configuration.landscapeId}&products=${productsStrings.join('-')}&isNight=${isNight}&cachekey=${cacheKey}`;
}

interface Props {
    cacheKey: string;
    configuration: IConfiguration;
    isNight: boolean;
    selectedCategory: ConfiguratorCategory;
    selectedLayers: ILayer[];
}

type State = {
    topSrc: string;
    loading: boolean;
    bottomSrc: string;
};


class Visualization extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            bottomSrc: '',
            topSrc: GenerateUrl(props.configuration, props.isNight, props.cacheKey),
        };

        this.changeImage = debounce(this.changeImage, 200);
    }

    public componentDidUpdate(prevProps: Props) {
        if (GenerateUrl(prevProps.configuration, prevProps.isNight, prevProps.cacheKey) !== GenerateUrl(this.props.configuration, this.props.isNight, this.props.cacheKey)) {
            this.changeImage(GenerateUrl(this.props.configuration, this.props.isNight, this.props.cacheKey));
        }
    }

    private changeImage(url: string) {
        this.setState({ loading: true, bottomSrc: this.state.topSrc, topSrc: '' }, () =>
            this.setState({ topSrc: url })
        );
    }

    public render() {
        const { topSrc, loading, bottomSrc } = this.state;

        const overlays: JSX.Element[] = [];

        if(this.props.selectedCategory !== "None" && this.props.selectedLayers.length === 1){
            for (let index = 0; index < this.props.selectedLayers.length; index++) {
                const layer = this.props.selectedLayers[index];
                
                if(layer.useOverlay){
                    overlays.push(<img key={layer.id} alt="" className='overlay' src={`${Settings.server}/api/layers/${layer.id}/overlay`} />)
                }
            }
        }

        return (
            <div className="visualization">
                <Spinner visible={loading} />
                {bottomSrc &&
                    <img src={bottomSrc} key={bottomSrc} alt='' />
                }
                {topSrc &&
                    <img
                        key={topSrc}
                        className={loading ? 'loading' : ''}
                        alt=''
                        src={topSrc}
                        onLoad={() => this.onLoad()}
                        onError={(e) => this.onError(topSrc, e)}
                    />
                }
                {overlays}
            </div>
        );
    }

    private onLoad() {
        if (this.state.loading) {
            this.setState({ loading: false });
        }
    }

    private onError(src: string, error: React.SyntheticEvent<HTMLImageElement, Event>) {
        console.warn(`There is an error with ${src}: ${error}`);
        this.setState({ loading: false });
    }
}


export default Visualization;
