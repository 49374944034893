import React from 'react';
import { FunctionComponent } from 'react';
import { ReactComponent as CloseIcon } from '../assets/schliessen.svg';
import './Popup.scss';
import { CSSTransition } from 'react-transition-group';

interface Props {
    visible: boolean;
    onClose: () => void;
    children?: JSX.Element | JSX.Element[];
    maxWidth: number;
}

const Popup: FunctionComponent<Props> = (props: Props) => {
    const Close = function () {
        props.onClose();
    }

    return (
        <CSSTransition in={props.visible} timeout={500} unmountOnExit={true} classNames="popup-container">
            <div className="popup-bg" onClick={() => Close()}>
                <div className="popup" style={{maxWidth:props.maxWidth}}>
                    <button className="close" onClick={() => Close()}><CloseIcon /></button>
                    <div className="popup-body" onClick={(e) => e.stopPropagation()}>
                        {props.children}
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default Popup;
