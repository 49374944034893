import * as React from 'react';
import { FunctionComponent } from 'react';
import './Toggle.scss';

interface Props {
    label: string;
    yesLabel: string;
    noLabel: string;
    checked: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
}

const Toggle: FunctionComponent<Props> = (props: Props) => {


    return (
        <label className={"toggle" + (props.disabled ? " disabled" : "")}>
            <span className='toggle-label'>{props.label}</span>
            <span className='toggle-content'>
                <span className={props.checked ? 'faded' : ''}>{props.yesLabel}</span>
                <input type="checkbox" checked={props.checked} onChange={(e) => (!props.disabled) && props.onChange(e.currentTarget.checked)} />
                <span className="checkmark"></span>
                <span className={props.checked ? '' : 'faded'}>{props.noLabel}</span>
            </span>
        </label>
    );
}

export default Toggle;
