import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { DataContext } from '../contexts/DataContext';
import useIsMobile from '../hooks/useIsMobile';
import OptionList from './OptionList';

interface Props {

}

const HousePicker: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('houses');
    const { data } = useContext(DataContext);
    const { currentConfiguration, changeHouse, setSelectedCategory } = useContext(ConfiguratorContext);
    const isMobile = useIsMobile();

    if (currentConfiguration === undefined) {
        return null;
    }

    const options = data.houses.map(e => ({
        id: e.id,
        thumbnail: `/api/houses/${e.id}/thumbnail?cachekey=${data.cacheKey}`,
        label: t(e.translationKey),
        selected: currentConfiguration.houseId === e.id,
        disabled: false,
    }));

    const selectHouse = (id:number) => {
        if(isMobile){
            setSelectedCategory('None');
        }
        changeHouse(id)
    }

    return (
        <>
            <h2>{t('title')}</h2>

            <div className='productPicker'>
                <OptionList options={options} onOptionClicked={option => selectHouse(option.id)} />
            </div>
        </>
    );
}

export default HousePicker;
