import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { FunctionComponent } from 'react';
import { IConfiguration } from '../model/Configuration';
import { Settings } from '../Settings';
import { DataContext } from '../contexts/DataContext';
import './ContactDialog.scss';
import Popup from './Popup';
import { ReactComponent as Bracket } from '../assets/Button-icon.svg';

interface Props {
    visible: boolean;
    onClose: () => void;
}

const GenerateUrl = (configuration: IConfiguration, region: number) => {    
    let productsStrings: string[] = [];

    for (let index = 0; index < configuration.products.length; index++) {
        const element = configuration.products[index];
        productsStrings.push(`${element.layer}.${element.product}.${element.color}`);
    }
    
    return `${Settings.server}/api/configurator/pdf?houseid=${configuration.houseId}&landscapeid=${configuration.landscapeId}&products=${productsStrings.join('-')}&regionId=${region}`;
}

const ContactDialog: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('contact');
    const { currentConfiguration } = useContext(ConfiguratorContext);
    const { data } = useContext(DataContext);
   
    const Close = function () {
        props.onClose();
    }

    return (
        <>
            <Popup visible={props.visible} onClose={Close} maxWidth={550}>
                <div className="contactdialog">
                    <h2>{t("header")}</h2>

                    {data.region.contactUrl && <a className='button large white border edge' target='blank' href={data.region.contactUrl}><span><Bracket />{t('contact')}</span></a>}

                    {data.region.getInfoUrl && <a className='button large white border edge' target='blank' href={data.region.getInfoUrl}><span><Bracket />{t('getinfo')}</span></a>}

                    {data.region.photoserviceUrl && <a className='button large white border edge' target='blank' href={data.region.photoserviceUrl}><span><Bracket />{t('photoservice')}</span></a>}

                    {data.region.orderUrl && <a className='button large white border edge' target='blank' href={data.region.orderUrl}><span><Bracket />{t('order')}</span></a>}

                    {data.region.findStoreUrl && <a className='button large white border edge' target='blank' href={data.region.findStoreUrl}><span><Bracket />{t('findstore')}</span></a>}

                </div>
            </Popup>
        </>
    );
}

export default ContactDialog;
