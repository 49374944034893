import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { DataContext } from '../contexts/DataContext';
import './Footer.scss';

interface Props {
}

const Footer: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('footer');
    const { data } = useContext(DataContext);

    return (
        <footer>
            {data.region.dataProtectionUrl && <a href={data.region.dataProtectionUrl} target='_blank'>{t('dataprotection')}</a>}
            {data.region.imprintUrl && <a href={data.region.imprintUrl} target='_blank'>{t('imprint')}</a>}
        </footer>
    );
}

export default Footer;
