import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import Popup from './Popup';
import './Infobox.scss';
import { Cookies } from 'react-cookie';

interface Props {

}

const InfoBox: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('infobox');
    const cookies = new Cookies()
    const [showInfoBox, setShowInfoBox] = useState(cookies.get("infobox") === undefined);
   
    const Close = function () {
        let expireDate = new Date().setDate(new Date().getDate() +1);
        cookies.set("infobox", 1, { path: '/', expires: new Date(expireDate), sameSite: 'none', secure: true})
        setShowInfoBox(false)      
    }

    return (
        <Popup visible={showInfoBox} onClose={Close} maxWidth={800}>
            <div className="infobox">
                <h1>{t("header")}</h1>
                <p>
                    {t("body")}
                </p>
                <div className="buttons">
                    <button className="button large" onClick={Close}>{t('ok')}</button>
                </div>
            </div>
        </Popup>
    );
}

export default InfoBox;
