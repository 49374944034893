import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import Popup from './Popup';
import './Infobox.scss';
import { Cookies } from 'react-cookie';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { DataContext } from '../contexts/DataContext';

interface Props {
    color: string;
}

const ColorInfoBox: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation('infobox');
    const cookies = new Cookies()
    const [showInfoBox, setShowInfoBox] = useState(false);
    const { currentConfiguration } = useContext(ConfiguratorContext);
    const { data } = useContext(DataContext);
    const [isDismissed, setIsDismissed] = useState(false);

    useEffect(() => {
        if(isDismissed){
            return;
        }

        let colorChosen = false;

        for (let i = 0; i < currentConfiguration.products.length; i++) {
            const product = data.products.find(e => e.id == currentConfiguration.products[i].product);

            if(product){
                const color = product.colors.find(e => e.id == currentConfiguration.products[i].color);

                if(color?.translationKey?.toLowerCase() == props.color.toLowerCase()){
                    colorChosen = true;
                    break;
                }
            }
        }

        if(colorChosen && cookies.get("infobox_color_" + props.color) === undefined){
            setShowInfoBox(true);
        }
    }, [currentConfiguration, isDismissed]);
   
    const Close = function () {
        let expireDate = new Date().setDate(new Date().getDate() +1);
        cookies.set("infobox_color_" + props.color, 1, { path: '/', expires: new Date(expireDate), sameSite: 'none', secure: true})
        setShowInfoBox(false);
        setIsDismissed(true); 
    }

    return (
        <Popup visible={showInfoBox} onClose={Close} maxWidth={800}>
            <div className="infobox">
                <h1>{t("header_" + props.color)}</h1>
                <p>
                    {t("body_" + props.color)}
                </p>
                <div className="buttons">
                    <button className="button large" onClick={Close}>{t('ok')}</button>
                </div>
            </div>
        </Popup>
    );
}

export default ColorInfoBox;
