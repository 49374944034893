import React, { useContext } from 'react';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import Visualization from '../components/Visualization';
import Hotspots from '../components/Hotspots';
import Menu from '../components/Menu';
import ConfiguratorMenu from '../components/ConfiguratorMenu';
import InfoBox from '../components/InfoBox';
import './Configurator.scss';
import { ReactComponent as Logo } from '../assets/PREFA_Logo_RGB_Positive.svg';
import { ReactComponent as MobileRotate } from '../assets/mobile-rotate-rotation.svg';
import { DataContext } from '../contexts/DataContext';
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import ColorInfoBox from './ColorInfoBox';

function Configurator() {
    const { currentConfiguration, selectedCategory, isNight, selectedLayers } = useContext(ConfiguratorContext);
    const { data } = useContext(DataContext);
    const { t } = useTranslation('cookies');

    if (currentConfiguration === undefined) {
        return null;
    }

    return (
        <>
            {/*<CookieConsent
                location="bottom"
                buttonText={t('accept')}
                cookieName="cookiesAccepted"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#FFF", background: "#FF000F", fontSize: "16px" }}
                expires={150}
                >
                {t('This website uses cookies')}{" "}
                <span style={{ fontSize: "13px" }}>We use cookies to analyse our traffic.</span>
    </CookieConsent>*/}
            <div className='mobile-portrait'><MobileRotate /></div>
            <div className={'configurator' + (selectedCategory === "None" ? "" : " menu-open")}>
                <Visualization configuration={currentConfiguration} isNight={isNight} selectedCategory={selectedCategory} selectedLayers={selectedLayers} cacheKey={data.cacheKey} />
                <Hotspots />
                <Menu />
                <ConfiguratorMenu />
                <a href={'//' + data.region.website} target="_parent">
                    <Logo className="logo" />
                </a>
                <Footer />
            </div>
            <ColorInfoBox color='bronzeR7048_duplicate' />
            <ColorInfoBox color='P10_ReinweissR9010_duplicate' />
            <InfoBox />
        </>
    );
}

export default Configurator;
