import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfiguratorContext } from '../contexts/ConfiguratorContext';
import { DataContext } from '../contexts/DataContext';
import { ILayer, IProductGroup, ProductType } from '../model/CustomizerData';
import OptionList, { IOption } from './OptionList';
import { ReactComponent as BackIcon } from '../assets/back.svg';
import { IConfigurationOption } from '../model/Configuration';
import Toggle from './Toggle';
import './ProductPicker.scss';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import useIsMobile from '../hooks/useIsMobile';

interface Props {
    productType: ProductType;
}

const ProductPicker: FunctionComponent<Props> = (props: Props) => {
    const { t } = useTranslation(props.productType.toLocaleLowerCase());
    const { data } = useContext(DataContext);
    const { currentConfiguration, changeProducts, setSelectedProduct, setSelectedProductGroup, selectedProduct, selectedProductGroup, selectedLayers, setSelectedLayers, setSelectedCategory } = useContext(ConfiguratorContext);
    const [animationDirection, setAnimationDirection] = useState<"next" | "prev" | "none">("next");
    const containerRef = useRef<HTMLDivElement>(null);
    const [productType, setProductType] = useState("" as ProductType);
    const isMobile = useIsMobile();

    useEffect(() => {
        setProductType(props.productType);
        setSelectedProductGroup(undefined);
        setSelectedProduct(undefined);

        const house = data.houses.find(e => e.id === currentConfiguration.houseId);
        if (house !== undefined) {
            const layers = house.layers.filter(e => e.type === props.productType);
            setSelectedLayers(layers);
        } else {
            setSelectedLayers([]);
        }

        const products = data.products.filter(e => e.type === props.productType && e.houses.includes(currentConfiguration.houseId));
        if (products.length === 1) {
            setSelectedProduct(products[0].id);
        }
    }, [props.productType, currentConfiguration.houseId, data.houses, data.products]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [selectedProduct, selectedProductGroup]);

    useEffect(() => {
        if (selectedProduct === undefined) {
            const products = data.products.filter(e => e.type === productType && e.houses.includes(currentConfiguration.houseId));
            if (products.length === 1) {
                setSelectedProduct(products[0].id);
            }
        }
    }, [data.products, selectedProductGroup, selectedProduct]);

    if (currentConfiguration === undefined) {
        return null;
    }

    let options: IOption[] = [];

    const house = data.houses.find(e => e.id === currentConfiguration.houseId);

    if (house === undefined) {
        return null;
    }

    const availableLayers = house.layers.filter(e => e.type === productType);
    const layers = house.layers.filter(e => selectedLayers.some(x => x.id === e.id));
    const currentOptions: IConfigurationOption[] = layers.map(e => currentConfiguration.products.find(p => p.layer === e.id)).filter(e => e !== undefined) as IConfigurationOption[];

    const layerParameter = selectedLayers.length === 1 ? `&layerId=${selectedLayers[0].id}` : '';
    let key = "";

    const isGroupSelected = (group: IProductGroup): boolean => {
        var products = data.products.filter(e => e.productGroupId == group.id);

        if (products.some(e => currentOptions.some(p => p.product === e.id))) {
            return true;
        }

        var groups = data.productGroups.filter(e => e.parentGroupId == group.id);

        if (groups.some(e => isGroupSelected(e))) {
            return true;
        }

        return false;
    }

    if (selectedProduct === undefined) {
        const groupOptions = data.productGroups.filter(e => e.type === productType && e.parentGroupId === selectedProductGroup).map(e => ({
            id: -e.id,
            thumbnail: `/api/productGroups/${e.id}/thumbnail?houseId=${house.id}${layerParameter}&cachekey=${data.cacheKey}`,
            label: t(e.translationKey),
            selected: isGroupSelected(e),
            disabled: false,
        }));

        const productOptions = data.products.filter(e => e.type === productType && e.productGroupId === selectedProductGroup && e.houses.includes(currentConfiguration.houseId)).map(e => ({
            id: e.id,
            thumbnail: `/api/products/${e.id}/thumbnail?houseId=${house.id}${layerParameter}&cachekey=${data.cacheKey}`,
            label: t(e.translationKey),
            selected: currentOptions.some(p => p.product === e.id),
            disabled: false,
            product: e,
        }));

        options = [...groupOptions, ...productOptions];
        key = "group_" + selectedProductGroup;
    } else {
        var product = data.products.find(e => e.id === selectedProduct);

        if (product !== undefined) {
            options = product.colors.map(e => ({
                id: e.id,
                label: t(e.translationKey, { ns: 'color' }),
                thumbnail: `/api/products/${product!.id}/thumbnail?houseId=${house.id}&colorId=${e.id}${layerParameter}&cachekey=${data.cacheKey}`,
                selected: currentOptions.some(p => p.product === selectedProduct && p.color === e.id),
                disabled: false,
            }));
        }
        key = "product_" + selectedProduct;
    }



    const selectOption = (optionId: number) => {
        setAnimationDirection("next");

        if (selectedProduct !== undefined) {
            if (isMobile) {
                setSelectedCategory('None');
            }
            selectProduct(selectedProduct, optionId);
            return;
        }

        if (optionId >= 0) {
            var product = data.products.find(e => e.id === optionId);



            if (product !== undefined) {
                if (product.colors.length === 0) {
                    if (isMobile) {
                        setSelectedCategory('None');
                    }
                    selectProduct(optionId, 0);
                } else if (product.colors.length === 1) {
                    if (isMobile) {
                        setSelectedCategory('None');
                    }
                    selectProduct(optionId, product.colors[0].id);
                } else {
                    setSelectedProduct(optionId);
                }
            }
        } else {
            setSelectedProductGroup(-optionId);
        }
    }

    const selectProduct = (productId: number, colorId: number) => {
        changeProducts(layers.map(e => e.id), productId, colorId);

        var product = data.products.find(e => e.id === productId);

        if (product != undefined) {
            var disableSolarpanel = product.type == "Roof" && product.translationKey.toLowerCase().includes("solar");

            if (disableSolarpanel) {
                const solarpanelLayers = house.layers.filter(e => e.type === "SolarPanel");
                changeProducts(solarpanelLayers.map(e => e.id), 0, 0);
            }
        }
    }

    let title = t('title');
    let showBackButton = false;

    if (selectedProductGroup !== undefined) {
        showBackButton = true;
        const group = data.productGroups.find(e => e.id === selectedProductGroup);

        if (group !== undefined) {
            title = t(group.translationKey);
        }
    }
    if (selectedProduct !== undefined) {
        showBackButton = true;
        const product = data.products.find(e => e.id === selectedProduct);

        if (product !== undefined) {
            title = t(product.translationKey);
        }

        const products = data.products.filter(e => e.type === productType && e.houses.includes(currentConfiguration.houseId));
        if (products.length === 1) {
            showBackButton = false;
        }
    }

    const goBack = () => {
        setAnimationDirection("prev");
        if (selectedProduct !== undefined) {
            setSelectedProduct(undefined);
        } else if (selectedProductGroup) {
            const group = data.productGroups.find(e => e.id === selectedProductGroup);

            if (group !== undefined) {
                setSelectedProductGroup(group.parentGroupId);
            } else {
                setSelectedProductGroup(undefined);
            }
        }
    }

    let layerPicker: JSX.Element | null = null;
    let extraOptions: JSX.Element[] = [];

    if (availableLayers.length > 1) {
        const changeLayers = (layer: ILayer | null) => {
            if (layer === null) {
                setSelectedLayers(availableLayers);
            } else {
                setSelectedLayers([layer]);
            }
        }

        layerPicker = <div className="layerpicker">
            <label><input type="radio" name="layer" checked={selectedLayers.length === availableLayers.length} onChange={() => changeLayers(null)} /><span className="checkmark"></span>{t('change all')}</label>
            {availableLayers.map((e, i) => <label key={e.id}><input type="radio" name="layer" value={e.id} checked={selectedLayers.length === 1 && selectedLayers[0].id === e.id} onChange={() => changeLayers(e)} /><span className="checkmark"></span>{t('change layer ' + i)}</label>)}
        </div>
    }

    if (productType === "Roof") {
        const snowstopperLayers = house.layers.filter(e => e.type === "SnowStopper");
        const snowstopperOption = data.products.find(e => e.type === "SnowStopper" && e.houses.includes(currentConfiguration.houseId));

        if (snowstopperOption !== undefined && snowstopperLayers.length > 0) {
            const selected = currentConfiguration.products.some(e => e.layer === snowstopperLayers[0].id);

            const selectSnowstopper = (selected: boolean) => {
                if (selected) {
                    changeProducts(snowstopperLayers.map(e => e.id), snowstopperOption.id, 0);
                } else {
                    changeProducts(snowstopperLayers.map(e => e.id), 0, 0);
                }
            }

            extraOptions.push(<div className="extraoption"><Toggle checked={!selected} label={t(snowstopperOption.translationKey)} yesLabel={t('yes')} noLabel={t('no')} onChange={(selected) => selectSnowstopper(!selected)} /></div>);
        }

        const solarpanelLayers = house.layers.filter(e => e.type === "SolarPanel");
        const solarpanelOptions = data.products.filter(e => e.type === "SolarPanel" && e.houses.includes(currentConfiguration.houseId));

        if (solarpanelLayers.length > 0) {
            for (let i = 0; i < solarpanelOptions.length; i++) {
                const solarpanelOption = solarpanelOptions[i];


                const roofproducts = data.products.filter(e => e.type == "Roof" && e.translationKey.toLowerCase().includes("solar"));
                let disabled = false;

                if (roofproducts.some(e => currentOptions.some(p => p.product === e.id))) {
                    disabled = true;
                }

                const selected = currentConfiguration.products.some(e => e.product === solarpanelOption.id);

                const selectSolarpanel = (selected: boolean) => {
                    if (selected) {
                        changeProducts(solarpanelLayers.map(e => e.id), solarpanelOption.id, 0);
                    } else {
                        changeProducts(solarpanelLayers.map(e => e.id), 0, 0);
                    }
                }

                extraOptions.push(<div className="extraoption extraoption2">
                    <Toggle disabled={disabled} checked={!selected} label={t(solarpanelOption.translationKey)} yesLabel={t('yes')} noLabel={t('no')} onChange={(selected) => selectSolarpanel(!selected)} />
                    </div>)
            }
        }

        const roofLayers = house.layers.filter(e => e.type === "Roof");
        const prefalzOption = data.products.find(e => e.type === "Roof" && e.translationKey == "Dachsystem Prefalz_500" && e.houses.includes(currentConfiguration.houseId));
        const prefalzSolarOption = data.products.find(e => e.type === "Roof" && e.translationKey == "Dachsystem Prefalz_500_SOLAR" && e.houses.includes(currentConfiguration.houseId));

        if(prefalzOption && prefalzSolarOption){
            if(product && product.translationKey.includes("Prefalz")){

                const selected = product.id == prefalzSolarOption.id;

                const selectSolarpanel = (selected: boolean) => {
                    if (selected) {
                        setAnimationDirection("none");
                        setSelectedProduct(prefalzSolarOption.id);

                        var prefalzLayers = currentConfiguration.products.filter(e => e.product == prefalzOption.id);
                        var solarLayers = house.layers.filter(e => e.type === 'SolarPanel');
                        if(prefalzLayers.length > 0){
                            changeProducts(prefalzLayers.map(e => e.layer), prefalzSolarOption.id, prefalzLayers[0].color);

                            if(solarLayers.length > 0) {
                                changeProducts(solarLayers.map(e => e.id), 0, 0);
                            }
                        }
                    } else {
                        setAnimationDirection("none");
                        setSelectedProduct(prefalzOption.id);

                        var prefalzLayers = currentConfiguration.products.filter(e => e.product == prefalzSolarOption.id);
                        if(prefalzLayers.length > 0){
                            changeProducts(prefalzLayers.map(e => e.layer), prefalzOption.id, prefalzLayers[0].color);
                        }
                    }
                }
                
                extraOptions.push(<div className="extraoption extraoption2">
                    <Toggle checked={!selected} label={t(prefalzSolarOption.translationKey)} yesLabel={t('yes')} noLabel={t('no')} onChange={(selected) => selectSolarpanel(!selected)} />
                </div>)
            }
        }
    }

    return (
        <>
            <div className='header'>
                <h2 className={`${showBackButton ? "hasback" : ""}`}>{title}</h2>
                {showBackButton ? <h3 className="back" onClick={() => goBack()}><BackIcon />{t('back')}</h3> : null}
            </div>

            {(extraOptions.length !== 0) && <div className='extraoptions'>
                {extraOptions}
            </div>}
            {layerPicker}

            <div ref={containerRef} className={`productPicker ${animationDirection}`}>
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={key}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade"
                    >
                        <OptionList options={options} onOptionClicked={option => selectOption(option.id)} />

                    </CSSTransition>
                </SwitchTransition>
            </div>

        </>
    );
}

export default ProductPicker;
